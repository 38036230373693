@charset "UTF-8";
@import "_setting";
@import "_mixin";
html,body{
}
body#top{
    .l-inner.clinic-list{
        max-width: 1600px;
        .u-top_clinicbox{
            .card_child{
                width: 33.3333333333%;
                width: calc(33.3333333333% - 20px);
                margin: 0 10px 40px 10px;
                @include trans;
                a,
                a:link,
                a:visited,
                a:active{
                    display: block;
                    p{
                        font-size: 1.4rem;
                        padding: 20px 0 10px;
                    }
                }
                a:hover{
                }
                font{
                    // font-size: 1.2rem;
                }
            }
            .card_child:hover{
                -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            }
        }
    }
    @include media(sp){
        .l-inner.clinic-list{
            max-width: 1600px;
            .u-top_clinicbox{
                .card_child{
                    width: 100%;
                    // width: 50%;
                    // width: calc(50% - 20px);
                    margin: 0 auto 40px auto;
                    @include trans;
                    a,
                    a:link,
                    a:visited,
                    a:active{
                        display: block;
                        p{
                            font-size: 1.25rem;
                            padding: 25px 0 0;
                            line-height: 1.6;
                        }
                    }
                    a:hover{
                    }
                    font{
                        // font-size: 1.2rem;
                    }
                }
                .card_child:hover{
                    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
}



@include media(sp){
body#top{
}
}


@media screen and (max-width: 980px) {

}
