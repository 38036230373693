/*
file: _setting.scss
Description: 
Version: 2.2.0
Changelog: 【URL】
Author: NAME
License: 
*/

// ------------------------------------------------- メディアクエリ
// ブレークポイント一覧
//
//           769px     960px     1280px
//            ○          ○         ○
//      （sp） ｜ （tab）  ｜  (pc)  ｜
//   --------▶｜◀-------▶｜◀-------------
//            ｜         ｜
//            ｜（tab-sp）｜
//   -------------------▶｜
//            ｜
//            ｜（screen）
//            ｜◀------------------------


$breakpoints: (
  screen:"only screen and (min-width:769px)",
      pc:"only screen and (min-width:960px)",
     tab:"only screen and (min-width:769px) and (max-width:959px)",
  tab-sp:"only screen and (max-width:959px)",
      sp:"only screen and (max-width:768px)",
);
@mixin media($breakpoint){
  @media #{map-get($breakpoints,$breakpoint)}{
    @content;
  }
}



// ------------------------------------------------------------------------- 案件・個人の好みで変更可能

// z-indexの管理（プロジェクトごとに管理）
// @include z-index(hoge);で管理
$layer:(
  hamburger    :9000,
  sp-nav       :8900,
  header       :1000,
  top-catch    :100,
  top-visual   :50,
  contents     :0,
  visual       :-20,
  contents-obj :-10
);


$blue:#178dc7;// ビルガイアブルー
$gray:#f1f2f6;// ビルガイアグレー
$black:#2a2a2a;  // ビルガイアブラック
$red:#bbbbbb;

$imgPath:'../img/';


// ------------------------------------------------------------------------- SNS系
$wp:#21759b;//WordPress
$tw:#55acee;//twitter
$yt:#cd201f;//YouTube
$fb:#315096;//Facebook




